import { AppLink } from '~atoms/AppLink/AppLink';
import { Typography } from '~atoms/Typography/Typography';

export const Logo = () => {
  return (
    <AppLink href="/#hero">
      <Typography as="h6" styling="logo">
        devtimate
      </Typography>
    </AppLink>
  );
};
